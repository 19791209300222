<template>
    <div
        v-editable="blok"
        class="grid gap-7 lg:gap-8"
        :class="props.noTitle ? 'lg:grid-cols-1' : ' lg:grid-cols-2'">
        <template v-if="currentCountry === Country.DE">
            <div v-if="!props.noTitle" class="flex flex-col justify-center gap-4">
                <h2>THG-Prämie</h2>

                <p class="text-lg sm:text-xl">
                    Jährlicher Geldregen für E-Autofahrer! Das schaffst du mit dem THG-Service von
                    INSTADRIVE! Melde dein Elektroauto in einem einfachen Prozess bei uns an, wir
                    kümmern uns um den Rest. Alle Informationen dazu gibt es im INSTADRIVE-THG
                    Portal.
                </p>
            </div>
            <div
                class="relative flex flex-col gap-7 rounded-3xl bg-green-100 p-6 text-blue-900 sm:p-8">
                <div>
                    <h3 class="text-lg lg:text-xl">Deine THG-Prämie</h3>
                    <div class="flex items-end justify-between gap-4">
                        <span class="sm:text-lg">Aktueller Marktwert (Fahrzeugklasse M1)</span>
                        <div>
                            <span class="mr-3 whitespace-nowrap text-xl font-bold sm:text-2xl">
                                €53
                            </span>
                            <span class="whitespace-nowrap text-sm sm:text-base">pro Jahr</span>
                        </div>
                    </div>
                </div>

                <div>
                    <h3 class="text-lg lg:text-xl">Theoretischer Maximalwert</h3>
                    <div class="space-y-3">
                        <div class="flex items-baseline justify-between gap-4">
                            <span class="sm:text-lg">Fahrzeugklasse M1</span>
                            <div>
                                <span class="mr-3 whitespace-nowrap text-xl font-bold sm:text-2xl"
                                    >€ 391</span
                                >
                                <span class="whitespace-nowrap text-sm sm:text-base">pro Jahr</span>
                            </div>
                        </div>
                        <div class="flex items-baseline justify-between gap-4">
                            <span class="sm:text-lg">Fahrzeugklasse N1</span>
                            <div>
                                <span class="mr-3 whitespace-nowrap text-xl font-bold sm:text-2xl"
                                    >€ 587
                                </span>
                                <span class="whitespace-nowrap text-sm sm:text-base">pro Jahr</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap justify-center gap-5">
                    <Button
                        class="w-full sm:w-max"
                        color="green"
                        intent="primary"
                        :to="buildEBonusPageUrl()">
                        Jetzt THG-Prämie sichern
                    </Button>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="!props.noTitle" class="flex flex-col justify-center gap-4">
                <h2>THG-Prämie ("ePrämie")</h2>

                <p class="text-lg sm:text-xl">
                    Jährlicher Geldregen für E-Autofahrer! Das schaffst du mit dem THG-Service von
                    INSTADRIVE! Melde dein Elektroauto in einem einfachen Prozess bei uns an, wir
                    kümmern uns um den Rest. Alle Informationen dazu gibt es im INSTADRIVE-THG
                    Portal.
                </p>
            </div>
            <div
                class="relative flex flex-col gap-7 rounded-3xl bg-green-100 p-6 text-blue-900 sm:p-8">
                <div>
                    <h3 class="text-xl lg:text-2xl">
                        Höchste THG-Prämie ("ePrämie") in Österreich
                    </h3>
                    <p class="sm:text-lg">Jetzt für 2024 Höchstprämie sichern!</p>
                </div>

                <div class="flex flex-wrap justify-center gap-5">
                    <Button
                        class="w-full sm:w-max"
                        color="green"
                        intent="primary"
                        :to="buildEBonusPageUrl()">
                        Jetzt THG-Prämie sichern
                    </Button>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import type { EBonusTileStoryblok } from '~/types/component-types-sb';
    import { Country } from '~/types/enums/Country';

    const props = defineProps<{
        blok?: EBonusTileStoryblok;
        noTitle?: boolean;
    }>();

    const currentCountry = useCurrentCountry();
</script>
